import React from "react";
import Resume from "../Components/Resume";
import Skills from "../Components/Skills";



const ResumePage = () => {
  return (
    <>
      <Skills />
      <Resume/>
    </>
  );
};

export default ResumePage;
